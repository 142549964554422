import Logo from "../../assets/images/logo.webp";
import RetoricCard from "../../designsystems/RetoricCard/RetoricCard";
import RetoricButton from "../../designsystems/RetoricButton/RetoricButton";
import {Divider, Form, Tooltip} from "antd";
import {LockOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {useAuth} from "../../extensions/Auth";
import NewLoginForm from "../../components/NewLoginForm/NewLoginForm";
import ExistingLoginForm from "../../components/ExistingLoginForm/ExistingLoginForm";
import OperatorLoginForm from "../../components/OperatorLoginForm/OperatorLoginForm";
import {IS_STANDALONE} from "../../services/LoginService";

export default function Landing() {
    const auth = useAuth();
    const [statusCheck, setStatusCheck] = useState(false);
    const [newReport, setNewReport] = useState(false);
    const [operatorLogin, setOperatorLogin] = useState(false);

    const [newReportForm] = Form.useForm();
    const [statusCheckForm] = Form.useForm();
    const [operatorLoginForm] = Form.useForm();

    const goBack = () => {
        statusCheckForm.resetFields();
        newReportForm.resetFields();
        operatorLoginForm.resetFields();

        setStatusCheck(false);
        setNewReport(false);
        setOperatorLogin(false);
    }

    return (
        <div
            className="grid lg:grid-cols-2 grid-cols-1 2xl:w-[60%] xl:w-[70%] lg:w-[80%] justify-center items-center lg:gap-16 gap-4">
            <div>
                <div className="flex flex-col justify-center items-center p-4">
                    <div className="flex flex-col justify-center items-center gap-4">
                        <h1 className="text-3xl tracking-wider font-light">Retoric Signalist</h1>
                        <img src={Logo} className="w-48" alt="Logo Signalist Retoric"/>
                        <p className="text-justify">Retoric Signalist to aplikacja internetowa, która pozwala na
                            anonimowe zgłaszanie nieprawidłowości w firmie. Aplikacja jest przeznaczona dla pracowników,
                            którzy chcą zgłosić nieprawidłowości zachowując pełną anonimowość.</p>
                    </div>
                </div>
            </div>
            {newReport &&
                <NewLoginForm goBack={goBack} form={newReportForm}/>}
            {statusCheck &&
                <ExistingLoginForm goBack={goBack} form={statusCheckForm}/>}
            {operatorLogin &&
                <OperatorLoginForm goBack={goBack} form={operatorLoginForm}/>}
            {!statusCheck && !newReport && !operatorLogin && <div className="flex flex-col">
                <RetoricCard
                    className="bg-offwhite flex flex-col justify-between items-center">
                    <div className="flex flex-col gap-2 items-center 2xl:px-16 xl:px-12 lg:px-8">
                        <h1 className="text-2xl tracking-wider font-light">Złóż anonimowe zgłoszenie</h1>
                        <p className="text-justify mb-4">Jeżeli chcesz zgłosić anonimowo nieprawidłowości w firmie,
                            kliknij poniższy przycisk. W celu
                            złożenia donosu będziesz musiał podać hasło firmowe, które powinno zostać przekazane
                            wszystkim
                            pracownikom. Hasło jest takie same dla wszystkich pracowników i nie pozwala ono na
                            zidentyfikowanie użytkownika.</p>
                    </div>
                    <div className="flex flex-col justify-center items-center gap-8">
                        <RetoricButton className="w-64" onClick={() => setNewReport(true)}>Zgłoś
                            naruszenie</RetoricButton>
                    </div>
                    <Divider/>
                    <div className="flex flex-col gap-2 items-center 2xl:px-16 xl:px-12 lg:px-8">
                        <h1 className="text-2xl tracking-wider font-light">Sprawdź status zgłoszenia</h1>
                        <p className="text-justify mb-4">Jeżeli zgłosiłeś już nieprawidłowości w firmie, możesz
                            sprawdzić
                            status swojego zgłoszenia. W celu sprawdzenia statusu zgłoszenia będziesz musiał podać
                            identyfikator zgłoszenia i wygenerowane hasło.</p>
                    </div>
                    <div className="flex flex-col justify-center items-center gap-8">
                        <RetoricButton className="w-64" onClick={() => setStatusCheck(true)}>Sprawdź
                            status</RetoricButton>
                    </div>
                </RetoricCard>
                {
                    IS_STANDALONE && <Tooltip title="Zaloguj się jako operator">
                        <div onClick={() => setOperatorLogin(true)}
                             className="absolute top-4 right-4 text-xl cursor-pointer text-brandColor-800 hover:text-brandColor-600 flex flex-col justify-center items-center">
                            <LockOutlined/>
                            <span className="block text-sm">Operator</span>
                        </div>
                    </Tooltip>
                }
            </div>}
        </div>
    )
}
