import RetoricBreadcrumbs from "../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../extensions/Locations";
import {CheckCircleTwoTone, ExclamationCircleTwoTone} from "@ant-design/icons";
import RetoricCard from "../../designsystems/RetoricCard/RetoricCard";
import React, {useEffect, useState} from "react";
import Infobox from "../../designsystems/Infobox/Infobox";
import './NewReport.css'
import {Form, Input, message, Radio, Select} from "antd";
import {ReportingStatus, ReportRequest, Reports} from "../../types/Reports";
import ReportService from "../../services/ReportService";
import RetoricButton from "../../designsystems/RetoricButton/RetoricButton";
import {Attachment} from "../../types/StaticAsset";
import AttachmentsDragger from "../../components/AttachmentsDragger/AttachmentsDragger";
import {useAuth} from "../../extensions/Auth";
import TextArea from "antd/es/input/TextArea";
import ConfirmationModal, {ConfirmationModalProps} from "../../designsystems/ConfirmationModal/ConfirmationModal";

export default function NewReport() {
    const auth = useAuth();
    const [form] = Form.useForm();
    const [reportMode, setReportMode] = useState(0);
    const [categories, setCategories] = useState([] as Reports[]);
    const [reportingStatuses, setReportingStatuses] = useState([] as ReportingStatus[]);
    const [attachments, setAttachments] = useState([] as Attachment[]);
    const [fileList, setFileList] = useState([] as any[]);
    const [activeReport, setActiveReport] = useState({} as { id: string, password: string });
    const [modelPayload, setModelPayload] = useState({} as ConfirmationModalProps);
    const [confirmModal, setConfirmModal] = useState(false);

    const updateReportMode = (mode: number) => {
        setReportMode(mode);
        form.setFieldValue('reportingMode', mode);
        form.setFieldValue('name', '');
    }

    const addReport = (values: any) => {
        const request: ReportRequest = {
            ...values,
            attachments: attachments.map(x => x.asset)
        }

        ReportService.addReport(request)
            .then(x => {
                message.success('Zgłoszenie zostało utworzone!');
                setActiveReport({id: x.id, password: request.password})
                auth.handleLogin(x.id, request.password)
                    .then(x => {
                        form.resetFields();
                        setAttachments([]);
                        setFileList([]);
                    })
                    .catch(x => {
                        message.error('Wystąpił błąd podczas przekierowania!');
                    })
            })
            .catch(x => {
                message.error('Wystąpił błąd podczas tworzenia zgłoszenia!');
            });
    }

    const submitReport = () => {
        form.validateFields().then((values) => {

            const payload = {
                title: 'Uwaga!',
                open: true,
                setOpen: setConfirmModal,
                yesAction: () => {
                    addReport(values);
                    setConfirmModal(false);
                },
                noAction: () => setConfirmModal(false),
            } as ConfirmationModalProps;

            const mode = values.reportingMode === 1 ? 'imiennym' : 'anonimowym';
            payload.infoboxTitle = `Planujesz złożyć zgłoszenie w trybie ${mode}`;

            if(values.reportingMode === 1) {
                payload.children = <>
                    <p className="text-slate-600">Składając zgłoszenie w trybie imiennym, zgadzasz się na przekazanie
                        Twoich danych operatorowi. Gdy zgłoszenie zostanie wysłane do operatora, nie będzie możliwe jego
                        usunięcie.
                    </p>
                    <p className="text-slate-600">Wysyłając zgłoszenie w trybie imiennym, będziesz chroniony przez ustawę
                        o sygnalistach.
                    </p>
                </>
            } else {
                payload.children = <>
                    <p className="text-slate-600">Składając zgłoszenie w trybie anonimowym nie będziesz chroniony przez
                        ustawę o sygnalistach. Operator nie będzie miał dostępu do Twoich danych.
                    </p>
                </>
            }

            setModelPayload(payload);
            setConfirmModal(true);
        });
    }

    useEffect(() => {
        ReportService.getCategories()
            .then((x) => setCategories(x))
            .catch(x => message.error('Wystąpił błąd podczas pobierania kategorii zgłoszeń.'));

        ReportService.getReportingStatuses()
            .then((x) => setReportingStatuses(x))
            .catch(x => message.error('Wystąpił błąd podczas pobierania statusów osoby zgłaszającej.'));

        form.setFieldValue('reportingMode', reportMode);
        setActiveReport({id: '', password: ''});
    }, []);

    return (
        <div>
            <RetoricBreadcrumbs locations={Locations['report/new']}>Nowe zgłoszenie</RetoricBreadcrumbs>
            {activeReport.id && activeReport.password &&
                <RetoricCard className={"bg-offwhite flex flex-col gap-4 justify-center items-center mt-4 lg:px-8"}>
                    <CheckCircleTwoTone className={"self-center text-4xl"}/>
                    <h2 className="text-2xl tracking-wider text-center">Zgłoszenie zostało utworzone pomyślnie</h2>
                    <div>
                        <p className="text-xl tracking-wider font-light text-center">Możesz sprawdzić postęp zgłoszenia
                            korzystając z
                            poniższych danych.</p>
                        <p className="text-xl tracking-wider font-light text-center">Te dane zostaną wyświetlone tylko
                            raz, upewnij się,
                            że zapisałeś/aś je w bezpiecznym miejscu.</p>
                    </div>
                    <div className={"flex flex-col"}>
                        <h3 className="text-lg font-medium text-center">Identyfikator zgłoszenia</h3>
                        <p className="text-lg text-center">{activeReport.id}</p>
                    </div>
                    <div className={"flex flex-col"}>
                        <h3 className="text-lg font-medium text-center">Hasło</h3>
                        <p className="text-lg text-center">{activeReport.password}</p>
                    </div>
                    <RetoricButton onClick={() => auth.navigate('/report/status')} className="mt-4 min-w-[200px]">Przejdź
                        do zgłoszenia</RetoricButton>
                </RetoricCard>}
            {!activeReport.id && !activeReport.password &&
                <RetoricCard className="bg-offwhite mt-4 lg:px-8 flex flex-col gap-4">
                    <h2 className="text-xl tracking-wider font-light">Podaj szczegóły zgłoszenia</h2>
                    <Infobox title={'Anonimowe zgłoszenie'}>
                        <div className="text-slate-600 infobox flex flex-col gap-1 text-justify">
                            <p className="mb-1 font-medium">Zgłoszenie jest w pełni anonimowe, system korzysta z
                                uwierzytelnienia JWT, które nie zawiera
                                żadnych informacji pozwalających na zidentyfikowanie użytkownika.</p>
                            <p><ExclamationCircleTwoTone className="text-xs mr-2"/>Jeżeli zalogowałeś/aś się za pomocą
                                hasła: <span className="italic">Hasło
                            jest takie same dla wszystkich pracowników i jest wykorzystywane w celu upewnienia się, że
                            zgłoszenie zostało utworzone przez pracownika firmy.</span></p>
                            <p><ExclamationCircleTwoTone className="text-xs mr-2"/>Jeżeli zalogowałeś/aś się za pomocą
                                Intranetu Retoric: <span className="italic">Intranet
                            utworzył anonimowy token JWT, który został przekazany do systemu zgłoszeń. Token ten nie
                            zawiera żadnych informacji pozwalających na identyfikację użytkownika.</span></p>
                        </div>
                    </Infobox>
                    <Form
                        onFinish={submitReport}
                        form={form}
                        layout="vertical">
                        <Form.Item label="Tytuł zgłoszenia" required name="title"
                                   rules={[{required: true, message: "Tytuł zgłoszenia jest wymagany!"}]}>
                            <Input className="rounded-none" placeholder="Tytuł zgłoszenia..." size="large"/>
                        </Form.Item>
                        <Form.Item label="Tryb zgłoszenia" required name="reportingMode"
                                   tooltip={"Tryb zgłoszenia określa, czy zgłoszenie jest anonimowe, czy imienne. W przypadku zgłoszenia imiennego, dane zgłaszającego będą widoczne dla operatora."}
                                   rules={[{required: true, message: "Tryb zgłoszenia jest wymagany!"}]}>
                            <Radio.Group size="large" value={reportMode} onChange={(e) => updateReportMode(e.target.value)}>
                                <Radio value={0}>Anonimowe</Radio>
                                <Radio value={1}>Imienne</Radio>
                            </Radio.Group>
                        </Form.Item>
                        {reportMode === 1 &&
                            <Form.Item label={"Imię i nazwisko"} required name="name"
                                       rules={[{required: true, message: "Imię i nazwisko są wymagane!"}]}>
                                <Input className="rounded-none" placeholder="Imię i nazwisko..." size="large"/>
                            </Form.Item>}
                        <Form.Item label="Kategoria" required name="categoryId"
                                   rules={[{required: true, message: "Kategoria jest wymagana!"}]}>
                            <Select placeholder={"Wybierz kategorię"} size="large">
                                {categories.map((x) => <Select.Option value={x.id}
                                                                      key={x.id}>{x.title}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Status osoby zgłaszającej" required name="reportingStatusId"
                                   rules={[{required: true, message: "Status osoby zgłaszającej jest wymagany!"}]}>
                            <Select placeholder={"Wybierz status"} size="large">
                                {reportingStatuses.map((x) => <Select.Option value={x.id}
                                                                             key={x.id}>{x.title}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Hasło" required name="password"
                                   rules={[{required: true, message: "Hasło jest wymagane!"}]}>
                            <Input.Password className="rounded-none" placeholder="Hasło..." size="large"/>
                        </Form.Item>
                        <Form.Item label="Powtórz hasło" required name="passwordConfirmation"
                                   rules={[{required: true, message: "Powtórz hasło"}]}>
                            <Input.Password className="rounded-none" placeholder="Powtórz hasło..." size="large"/>
                        </Form.Item>
                        <Form.Item label="Treść zgłoszenia" required name="description"
                                   rules={[{required: true, message: "Treść zgłoszenia jest wymagana!"}]}>
                            <TextArea rows={6} placeholder={"Treść zgłoszenia..."}></TextArea>
                        </Form.Item>
                        <Form.Item>
                            <AttachmentsDragger attachments={attachments} setAttachments={setAttachments}
                                                fileList={fileList} setFileList={setFileList}/>
                        </Form.Item>
                        <Form.Item className="flex justify-center">
                            <RetoricButton htmlType="submit"
                                           className="lg:min-w-[200px] lg:self-center self-stretch min-w-full mt-4">Utwórz
                                zgłoszenie</RetoricButton>
                        </Form.Item>
                    </Form>
                    <ConfirmationModal {...modelPayload} open={confirmModal} setOpen={setConfirmModal}/>
                </RetoricCard>}
        </div>
    )
}
